<template>
  <!-- NOTE (TEMPLATE) to show all proposals -->

  <div class="animated fadeIn">
    <v-col cols="10" class="mx-auto">
      <v-tabs background-color="transparent" vertical v-model="tab">
        <v-tab
          class="tab-title first-tab-item-br"
          style="border-radius: 0 !important"
          >تغییرات آخرین نسخه</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important">
          تاریخچه بروزرسانی‌های سامانه
        </v-tab>
        <v-tabs-items
          v-model="tab"
          style="border-radius: 30px; border-top-right-radius: 0"
        >
          <!-- تغییرات آخرین نسخه -->
          <v-tab-item>
            <v-card class="px-8 pt-5 py-8">
              <h3 class="font-weight-bold primary--text">نسخه 1.4.0</h3>
              <ul class="mt-2 ps-6 blue-grey--text text--darken-3 ul-fontsize">
                <li class="primary--text font-weight-bold">
                  تاریخ انتشار: 27 اردیبهشت 1402
                </li>
                <li>افزودن بخش دبیرخانه به سیستم</li>
                <li>افزودن قابلیت ثبت درخواست توسط بیمار</li>
                <li>افزودن بخش فیش حقوقی برای هر کارمند</li>
                <li>افزودن بخش معاینات بدواستخدام برای هر کارمند</li>
                <li>بهبود عملکرد سیستم</li>
              </ul>
            </v-card>
          </v-tab-item>

          <!-- تاریخچه بروزرسانی‌های سامانه -->
          <v-tab-item>
            <v-card class="px-8 pt-1 py-8">
              <ul class="ps-5">
                <li class="version-major">نسخه 0.1.0</li>
                <ul class="ul-fontsize blue-grey--text text--darken-3">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 26 شهریور 1399
                  </li>
                  <li>نسخه اولیه پروژه</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.1.1
                </li>
                <ul class="ul-fontsize ps-5 blue-grey--text text--darken-3">
                  <li class="primary--text">تاریخ انتشار: 2 مهر 1399</li>
                  <li>افزودن صفحات اصلی نقش‌های پذیرش و ادمین</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.1.2
                </li>
                <ul class="ul-fontsize ps-5 blue-grey--text text--darken-3">
                  <li class="primary--text">تاریخ انتشار: 9 مهر 1399</li>
                  <li>افزودن صفحه ورود کاربر</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.1.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 16 مهر 1399</li>
                  <li>قابلیت افزودن و ویرایش سرویس‌ها، بیماران و کادر درمان</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.1.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 30 مهر 1399</li>
                  <li>قابلیت ثبت، ویرایش و مدیریت درخواست</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.1.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 7 آبان 1399</li>
                  <li>قابلیت مشاهده، افزودن و ویرایش بیمه</li>
                </ul>
                <li class="version-major">نسخه 0.2.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 14 آبان 1399
                  </li>
                  <li>دریافت اکسل فایل‌های مورد نیاز</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.2.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 21 آبان 1399</li>
                  <li>ظاهر اولیه پروژه</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.2.2
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 28 آبان 1399</li>
                  <li>افزودن قابلیت اتمام شیفت</li>
                  <li>بهبود عملکرد درخواست‌ها و سرویس‌ها</li>
                  <li>رفع یک سری از مشکلات و بهبود عملکرد</li>
                  <li>رفع یک سری از مشکلات ظاهری</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.2.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 5 آذر 1399</li>
                  <li>بروزرسانی پکیج‌‌های استفاده شده در نرم‌افزار</li>
                  <li>افزودن صفحه کادر درمان برای پذیرش</li>
                  <li>افزودن گزارش و عملیات مربوط به کادر درمان برای پذیرش</li>
                  <li>بهبود قابلیت ثبت درخواست</li>
                  <li>بهبود عملیات پرینت</li>
                </ul>

                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.2.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 12 آذر 1399</li>
                  <li>بررسی مشکلات و رفع آنها در جهت بهبود عملکرد سیستم</li>
                  <li>بهبود ظاهر پرینت</li>
                  <li>
                    بهبود عملیات ثبت و ویرایش در بخش درخواستها ( افزودن پرستار)
                  </li>
                  <li>بهبود گزارش کادر درمان ( افزودن صفحه آن برای ادمین )</li>
                </ul>

                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.2.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 19 آذر 1399</li>
                  <li>بهبود عملکرد ویرایش دکتر</li>
                  <li>افزودن بخش کادر پذیرش و عملیات مربوط به آن</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.2.6
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 26 آذر 1399</li>
                  <li>قابلیت ویرایش و بازیابی رمز عبور</li>
                  <li>مشاهده خدمات و درآمد پزشک</li>
                  <li>دریافت اکسل گزارش‌های مورد نیاز</li>
                </ul>
                <li class="version-major">نسخه 0.3.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 3 دی 1399
                  </li>
                  <li>بهبود ظاهر سیستم</li>
                  <li>بهبود قابلیت تغییر شیفت و reset آن</li>
                  <li>افزودن مشاهده سرویس در منزل و ویرایش آن</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.3.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 10 دی 1399</li>
                  <li>افزودن مشاهده، مدیریت و ثبت درخواست در منزل</li>
                  <li>قابلیت افزودن بیمار در درخواست در منزل</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.3.2
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 17 دی 1399</li>
                  <li>قابلیت ویرایش شیفت در حال جریان</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.3.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 24 دی 1399</li>
                  <li>افزودن و بهبود گزارش خدمات شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.3.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 8 بهمن 1399</li>
                  <li>افزودن قابلیت تغییر اطلاعات شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.3.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 15 بهمن 1399</li>
                  <li>بهبود صفحه گزارش کادر درمان</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.3.6
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 22 بهمن 1399</li>
                  <li>افزودن قابلیت فیلتر کردن بیماران</li>
                  <li>افزودن بخش گزارش روند و بهبود آن</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.3.7
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 29 بهمن 1399</li>
                  <li>افزودن بخش پاراکلینیک و آزمایشگاه به ثبت درخواست</li>
                </ul>
                <li class="version-major">نسخه 0.4.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 6 اسفند 1399
                  </li>
                  <li>افزودن پنل مدیر</li>
                  <li>افزودن قابلیت مشاهده روزانه گزارش شیفت</li>
                  <li>افزودن قابلیت آپلود گزارش در ویرایش خدمت در منزل</li>
                  <li>بهبود قابلیت افزودن بیمار</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.4.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 13 اسفند 1399</li>
                  <li>افزودن vuetify و بهبود ظاهر نرم‌افزار</li>
                  <li>بهبود دایالوگ تغییر شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.4.2
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 20 اسفند 1399</li>
                  <li>افزودن صفحه کارمندان خدمات و عملیات مربوط به آنها</li>
                  <li>افزودن گزارش خدمات کارمندان پذیرش</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.4.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 11 فروردین 1400</li>
                  <li>افزودن بخش خدمات تخصصی</li>
                  <li>بهبود عملکرد بخش سرویس‌ها</li>
                  <li>بهبود گزارش روند</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.4.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 18 فروردین 1400</li>
                  <li>قابلیت گرفتن گزارش روزانه و دریافت اکسل آن</li>
                  <li>حذف پکیج‌های اضافی جهت افزایش سرعت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.4.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 25 فروردین 1400</li>
                  <li>کامنت گذاری سیستم</li>
                  <li>افزودن mixin</li>
                  <li>تمیز کردن کد</li>
                </ul>
                <li class="version-major">نسخه 0.5.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 1 اردیبهشت 1400
                  </li>
                  <li>افزودن و بهبود صفحه گزارش ساعات فعالیت</li>
                  <li>افزودن جدول شیفت‌ها به گزارش پذیرش</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.5.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 8 اردیبهشت 1400</li>
                  <li class="font-weight-bold">افزودن فرم پایان شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.5.2
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 15 اردیبهشت 1400</li>
                  <li>بهبود عملیات آغاز و پایان شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.5.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 22 اردیبهشت 1400</li>
                  <li>افزودن بخش دندانپزشکی به ثبت درخواست</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.5.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 5 خرداد 1400</li>
                  <li>افزودن صفحه خدمات دندانپزشکی و عملیات مربوط به آن</li>
                  <li>تغییر Loading</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.5.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 19 خرداد 1400</li>
                  <li>افزودن جدول ساعت ورود و خروج به گزارش شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.5.6
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 26 خرداد 1400</li>
                  <li>بهبود فیلد‌های مربوط به قیمت</li>
                </ul>
                <li class="version-major">نسخه 0.6.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 2 تیر 1400
                  </li>
                  <li>افزودن کد اشتراک به فیلتر بیماران</li>
                  <li>تغییر زیرساخت و افزایش سرعت پاسخ‌دهی</li>
                  <li>بهبود عملکرد صفحات با دارای المان‌های مشابه</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 9 تیر 1400</li>
                  <li>افزودن قابلیت کنترل سطح دسترسی در تمامی مراحل کار</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.2
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 16 تیر 1400</li>
                  <li>افزودن بخش دندانپزشکی به گزارش شیفت و گزارش روند</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 23 تیر 1400</li>
                  <li>استفاده از vuex برای بهبود سرعت</li>
                </ul>

                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 6 مرداد 1400</li>
                  <li>ویرایش صفحه عملکرد بیمه</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 13 مرداد 1400</li>
                  <li>افزودن قابلیت تغییر شیفت درخواست به ویرایش درخواست</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.6
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 20 مرداد 1400</li>
                  <li>بهبود ظاهر پیام‌های نمایش داده شده در صفحات</li>
                  <li>افزودن مجموع درمانگاه عمومی به گزارش شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.7
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 3 شهریور 1400</li>
                  <li>بروزرسانی هسته سیستم</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.8
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 10 شهریور 1400</li>
                  <li>
                    نمایش تعداد مراجعه بیمار در ثبت درخواست در منزل و درمانگاه
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.9
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 17 شهریور 1400</li>
                  <li>بهبود نمایش سهم پزشک از خدمات</li>
                  <li>بهینه سازی فرآیند بستن شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.10
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 24 شهریور 1400</li>
                  <li>افزودن بخش هزینه آزمایشگاه به ثبت درخواست</li>
                  <li>افزودن صفحه گزارش آزمایشگاه</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.6.11
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 31 شهریور 1400</li>
                  <li>یکتاسازی سیستم سخت‌افزاری کاربر</li>
                  <li>بهبود فیلد‌های تاریخ</li>
                </ul>
                <li class="version-major">نسخه 0.7.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 7 مهر 1400
                  </li>
                  <li>ظاهر جدید کلینیک</li>
                </ul>

                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 14 مهر 1400</li>
                  <li>بهبود سیستم سخت‌افزاری کاربر</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.2
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 21 مهر 1400</li>
                  <li>ویرایش فونت pdf پرینت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 28 مهر 1400</li>
                  <li>
                    اضافه شدن قسمت آزمایشگاه: ثبت درخواست، لیست درخواست‌ها،
                    جوابدهی و جزییات هر آزمایش
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 5 آبان 1400</li>
                  <li>حذف coreUi و بهبود سایز فونت‌ها</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 5 آبان 1400</li>
                  <li>بهبود صفحات مربوط به پذیرش آزمایشگاه</li>
                  <li>فیلتر آزمایش‌ها با شماره آزمایش</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.6
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 12 آبان 1400</li>
                  <li>بهبود ظاهر پی دی اف برای پرینت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.7
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 19 آبان 1400</li>
                  <li>بهبود ظاهر صفحه ورودی نرم‌افزار</li>
                  <li>ویرایش درخواست آزمایشگاه</li>
                  <li>خروجی اکسل از خدمات ارائه شده</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.8
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 26 آبان 1400</li>
                  <li>رفع اشکال چاپ شدن منو هنگام پرینت گزارش روند</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.7.9
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 26 آبان 1400</li>
                  <li>افزودن قسمت آزمایشگاه به گزارش شیفت</li>
                </ul>
                <li class="version-major">نسخه 0.8.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 3 آذر 1400
                  </li>
                  <li>افزودن قابلیت گرفتن خروجی عکس نمودارهای گزارش‌ها</li>
                  <li>
                    بهبود صفحه گزارش شیفت و افزودن قسمت مالی آزمایشگاه به آن
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 10 آذر 1400</li>
                  <li>
                    افزودن صفحه تمامی کارمندان و ویرایش هر کارمند (پزشک، پرستار،
                    کارمند خدمات و پذیرش)
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.2
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 17 آذر 1400</li>
                  <li>
                    افزودن بخش بایگانی فایل، تسویه حساب، لیست دریافتی‌ها و
                    پرداختی‌ها به پرونده هر کارمند
                  </li>
                  <li>افزودن بخش‌های نامه‌های دریافتی و ارسالی (دبیرخانه)</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 24 آذر 1400</li>
                  <li>
                    افزودن بخش‌های دریافتی‌ها، پرداختی‌ها و مرخصی‌های ثبت شده در
                    درمانگاه برای ادمین
                  </li>
                  <li>
                    افزودن قسمت پرداخت به درخواست در منزل، درخواست آزمایشگاه و
                    درمانگاه
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 24 آذر 1400</li>
                  <li>بهبود صفحه ثبت درخواست</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 1 دی 1400</li>
                  <li>حل مشکلات گزارش شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.6
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 8 دی 1400</li>
                  <li>
                    بهبود بخش‌های تسویه حساب، بایگانی فایل، پرداختی ها،
                    دریافتی‌ها، مرخصی‌ها در پرونده کارمند
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.7
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 15 دی 1400</li>
                  <li>بهبود بخش‌های دریافتی‌ها و پرداختی‌های درمانگاه</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.8
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 22 دی 1400</li>
                  <li>
                    افزودن قابلیت پرداخت از راه نقدی-کارتخوان به درخواستهای
                    آزمایشگاه، درخواست جدید خدمت در منزل و درمانگاه پذیرش
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.9
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 29 دی 1400</li>
                  <li>بهبود قابلیت پرداخت نقدی-کارتخوان</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.10
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 6 بهمن 1400</li>
                  <li>بهبود ظاهر ستون توضیحات جداول</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.11
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 13 بهمن 1400</li>
                  <li>بهبود ویرایش پرونده کارمند</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.12
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 20 بهمن 1400</li>
                  <li>
                    افزودن صفحات بیمه‌های تامین اجتماعی و مسلح و صفحات گزارشات
                    ناقص آنها
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.13
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 20 بهمن 1400</li>
                  <li>بهبود بخش پرداختی‌ها، دریافتی‌ها و نامه‌ها</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.8.14
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 27 بهمن 1400</li>
                  <li>بهبود ظاهر سیستم</li>
                </ul>
                <li class="version-major">نسخه 0.9.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 25 اسفند 1400
                  </li>
                  <li>افزودن بخش خدمات و ساعات کارکرد به پرونده کارمند</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.9.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 17 فروردین 1401</li>
                  <li>افزودن تب درآمد به پرونده پزشک</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.9.2
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 24 فروردین 1401</li>
                  <li>بهبود ظاهر و عملکرد سیستم</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.9.3
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 31 فروردین 1401</li>
                  <li>افزودن صفحه آخر دفترچه در قسمت‌های مورد نیاز</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.9.4
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 7 اردیبهشت 1401</li>
                  <li>
                    افزودن صفحات بیمه‌های تامین اجتماعی و مسلح برای نقش ادمین
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.9.5
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 4 خرداد 1401</li>
                  <li>افزودن شماره بیمه به صفحات بیمه مسلح</li>
                  <li>
                    افزودن قابلیت ویرایش اطلاعات بیمه بیمار در ثبت درخواست
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.9.6
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 11 خرداد 1401</li>
                  <li>بهبود قسمت ویرایش بیمه بیمار در ثبت درخواست</li>
                  <li>قابلیت تفکیک لیست اقلام مصرفی در گزارش خدمات شیفت</li>
                  <li>بهبود قسمت تفکیک گزارش مالی در گزارش خدمات شیفت</li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.9.7
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 18 خرداد 1401</li>
                  <li>تغییر فرم تسویه حساب</li>
                  <li>افزودن قابلیت ثبت بازخورد برای همه نقش‌ها</li>
                  <li>
                    نمایش لیست بازخوردهای ثبت شده برای ادمین به همراه قابلیت‌های
                    ثبت یادداشت و تغییر وضعیت
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 0.9.8
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 25 خرداد 1401</li>
                  <li>بهبود پرینت فرم تسویه حساب</li>
                  <li>
                    افزودن قابلیت پرداخت جزئی در ثبت درخواست درمانگاه و خدمت در
                    منزل
                  </li>
                  <li>افزودن بخش پرداخت‌ها به پروفایل بیمار</li>
                  <li>نمایش پرداختی‌های درخواست در منزل برای نقش ادمین</li>
                  <li>افزودن تاریخچه توسعه</li>
                </ul>
                <li class="version-major">نسخه 1.0.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 8 تیر 1401
                  </li>
                  <li>قابلیت ثبت زمان آزاد برای پزشک توسط ادمین و پذیرش</li>
                  <li>قابلیت ثبت نوبت‌ توسط پذیرش</li>
                  <li>افزودن دسترسی نقش‌های پزشک و پرستار به سامانه</li>
                  <li>قابلیت مشاهده ویزیت‌های تخصیص یافته به پزشک و پرستار</li>
                  <li>
                    قابلیت مشاهده لیست کلیه ویزیت‌ها و نوبت دهی‌ها توسط پزشک
                    شیفت
                  </li>
                  <li>قابلیت ثبت دریافتی جدید توسط پذیرش</li>
                  <li>
                    بهبود جزئیات درخواست و افزودن قابلیت پرینت آن برای ادمین
                  </li>
                </ul>
                <li class="version-minor blue-grey--text text--darken-3">
                  نسخه 1.0.1
                </li>
                <ul class="ul-fontsize ps-5">
                  <li class="primary--text">تاریخ انتشار: 15 تیر 1401</li>
                  <li>قابلیت ارجاع بیمار به تخصص موردنظر توسط پزشک معالج</li>
                  <li>نوبت دهی بیماران ارجاع داده‌شده به پزشک متخصص</li>
                  <li>
                    افزودن قابلیت ورود با استفاده از رمز عبور برای کادر درمان
                  </li>
                  <li>
                    قابلیت تغییر رمز عبور کادر درمان توسط ادمین و کادر درمان
                  </li>
                  <li>
                    قابلیت مشاهده لیست درخواست‌های دندانپزشکی برای ادمین و پذیرش
                  </li>
                </ul>
                <li class="version-major">نسخه 1.1.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 2 شهریور 1401
                  </li>
                  <li>افزودن درمانگاه دندانپزشکی به سامانه</li>
                  <li>بهبود پرینت گزارش عملکرد</li>
                </ul>
                <li class="version-major">نسخه 1.2.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 12 مهر 1401
                  </li>
                  <li>افزودن مدیریت درمانگاه دندانپزشکی به سامانه</li>
                  <li>افزودن قابلیت صندوق دیوار مهربانی سلامت</li>
                  <li>بهبود درمانگاه دندانپزشکی</li>
                  <li>بهبود ظاهر سیستم</li>
                </ul>
                <li class="version-major">نسخه 1.3.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 12 مهر 1401
                  </li>
                  <li>بهبود ظاهر سیستم در موبایل برای کادر درمان و پذیرش</li>
                  <li>بهبود عملکرد سیستم</li>
                </ul>
                <li class="version-major">نسخه 1.4.0</li>
                <ul class="ul-fontsize">
                  <li class="primary--text font-weight-bold">
                    تاریخ انتشار: 27 اردیبهشت 1402
                  </li>
                  <li>افزودن بخش دبیرخانه به سیستم</li>
                  <li>افزودن قابلیت ثبت درخواست توسط بیمار</li>
                  <li>افزودن بخش فیش حقوقی برای هر کارمند</li>
                  <li>افزودن بخش معاینات بدواستخدام برای هر کارمند</li>
                  <li>بهبود عملکرد سیستم</li>
                </ul>
              </ul>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      tab: null,
    };
  },

  methods: {},
  updated() {},
  mounted() {},
};
</script>

<style>
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../assets/fonts/IRANSans(FaNum).ttf");
}

.color {
  background-color: #0c4961;
  color: white;
}

.version-major {
  font-weight: bold;
  font-size: 280%;
  margin-top: 25px;
  color: #00a7b7;
}

.version-minor {
  font-weight: bold;
  font-size: 200%;
  margin-top: 10px;
  margin-right: 15px;
}

.version-minor2 {
  font-weight: bold;
  font-size: 130%;
  margin-top: 10px;
  margin-right: 15px;
}

.version-patch {
  font-style: italic;
  margin-top: 5px;
  margin-right: 25px;
}

.version-major-future {
  font-weight: bold;
  font-size: 135%;
  color: gray;
  margin-top: 25px;
}

.version-minor-future {
  font-weight: bold;
  font-size: 120%;
  color: gray;
  margin-top: 15px;
  margin-right: 15px;
}

.version-patch-future {
  font-style: italic;
  color: gray;
  margin-top: 5px;
  margin-right: 25px;
}

.v-tabs-slider {
  display: none;
}

.ul-fontsize {
  font-size: 150%;
  color: #37474f;
}
</style>
